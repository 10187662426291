import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        subItems: [
            {
                id: 2,
                label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
                link: '/dashboard',
                parentId: 1
            },
            {
                id: 3,
                label: 'MENUITEMS.DASHBOARDS.LIST.SAAS',
                link: '/dashboards/saas',
                parentId: 1
            },
            {
                id: 4,
                label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
                link: '/dashboards/crypto',
                parentId: 1
            },
            {
                id: 5,
                label: 'MENUITEMS.DASHBOARDS.LIST.BLOG',
                link: '/dashboards/blog',
                parentId: 1
            },
        ]
    },
    // {
    //     id: 6,
    //     label: 'MENUITEMS.UIELEMENTS.TEXT',
    //     icon: 'bx-tone',
    //     isUiElement: true,
    //     subItems: [
    //         {
    //             id: 7,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.ALERTS',
    //             link: '/ui/alerts',
    //             parentId: 6
    //         },
    //         {
    //             id: 8,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.BUTTONS',
    //             link: '/ui/buttons',
    //             parentId: 6
    //         },
    //         {
    //             id: 9,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CARDS',
    //             link: '/ui/cards',
    //             parentId: 6
    //         },
    //         {
    //             id: 10,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CAROUSEL',
    //             link: '/ui/carousel',
    //             parentId: 6
    //         },
    //         {
    //             id: 11,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.DROPDOWNS',
    //             link: '/ui/dropdowns',
    //             parentId: 6
    //         },
    //         {
    //             id: 12,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.GRID',
    //             link: '/ui/grid',
    //             parentId: 6
    //         },
    //         {
    //             id: 13,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.IMAGES',
    //             link: '/ui/images',
    //             parentId: 6
    //         },
    //         {
    //             id: 14,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.MODALS',
    //             link: '/ui/modals',
    //             parentId: 6
    //         },
    //         {
    //             id: 15,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.RANGESLIDER',
    //             link: '/ui/rangeslider',
    //             parentId: 6
    //         },
    //         {
    //             id: 16,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.PROGRESSBAR',
    //             link: '/ui/progressbar',
    //             parentId: 6
    //         },
    //         {
    //             id: 17,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.SWEETALERT',
    //             link: '/ui/sweet-alert',
    //             parentId: 6
    //         },
    //         {
    //             id: 18,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.TABS',
    //             link: '/ui/tabs-accordions',
    //             parentId: 6
    //         },
    //         {
    //             id: 19,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.TYPOGRAPHY',
    //             link: '/ui/typography',
    //             parentId: 6
    //         },
    //         {
    //             id: 20,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.VIDEO',
    //             link: '/ui/video',
    //             parentId: 6
    //         },
    //         {
    //             id: 21,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.GENERAL',
    //             link: '/ui/general',
    //             parentId: 6
    //         },
    //         {
    //             id: 22,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.COLORS',
    //             link: '/ui/colors',
    //             parentId: 6
    //         },
    //         {
    //             id: 23,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CROPPER',
    //             link: '/ui/image-crop',
    //             parentId: 6
    //         },
    //     ]
    // },
    // {
    //     id: 24,
    //     label: 'MENUITEMS.APPS.TEXT',
    //     icon: 'bx-customize',
    //     subItems: [
    //         {
    //             id: 25,
    //             label: 'MENUITEMS.CALENDAR.TEXT',
    //             link: '/calendar',
    //             parentId: 24
    //         },
    //         {
    //             id: 26,
    //             label: 'MENUITEMS.CHAT.TEXT',
    //             link: '/chat',
    //             parentId: 24
    //         },
    //         {
    //             id: 27,
    //             label: 'MENUITEMS.FILEMANAGER.TEXT',
    //             link: '/filemanager',
    //             parentId: 24
    //         },
    //         {
    //             id: 28,
    //             label: 'MENUITEMS.EMAIL.TEXT',
    //             subItems: [
    //                 {
    //                     id: 29,
    //                     label: 'MENUITEMS.EMAIL.LIST.INBOX',
    //                     link: '/email/inbox',
    //                     parentId: 28
    //                 },
    //                 {
    //                     id: 30,
    //                     label: 'MENUITEMS.EMAIL.LIST.READEMAIL',
    //                     link: '/email/read',
    //                     parentId: 28
    //                 },
    //                 {
    //                     id: 31,
    //                     label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.TEXT',
    //                     parentId: 28,
    //                     subItems: [
    //                         {
    //                             id:32 ,
    //                             label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BASIC',
    //                             link: '/email/basic',
    //                             parentId:31 
    //                         },
    //                         {
    //                             id:33 ,
    //                             label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.ALERT',
    //                             link: '/email/alert',
    //                             parentId:31 
    //                         },
    //                         {
    //                             id:34 ,
    //                             label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BILLING',
    //                             link: '/email/billing',
    //                             parentId:31 
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             id: 35,
    //             label: 'MENUITEMS.ECOMMERCE.TEXT',
    //             subItems: [
    //                 {
    //                     id: 36,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
    //                     link: '/ecommerce/products',
    //                     parentId: 35
    //                 },
    //                 {
    //                     id: 37,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTDETAIL',
    //                     link: '/ecommerce/product-detail/1',
    //                     parentId: 35
    //                 },
    //                 {
    //                     id: 38,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.ORDERS',
    //                     link: '/ecommerce/orders',
    //                     parentId: 35
    //                 },
    //                 {
    //                     id: 39,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.CUSTOMERS',
    //                     link: '/ecommerce/customers',
    //                     parentId: 35
    //                 },
    //                 {
    //                     id: 40,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.CART',
    //                     link: '/ecommerce/cart',
    //                     parentId: 35
    //                 },
    //                 {
    //                     id: 41,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.CHECKOUT',
    //                     link: '/ecommerce/checkout',
    //                     parentId: 35
    //                 },
    //                 {
    //                     id: 42,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.SHOPS',
    //                     link: '/ecommerce/shops',
    //                     parentId: 35
    //                 },
    //                 {
    //                     id: 43,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.ADDPRODUCT',
    //                     link: '/ecommerce/add-product',
    //                     parentId: 35
    //                 },
    //             ]
    //         },
    //         {
    //             id: 44,
    //             label: 'MENUITEMS.CRYPTO.TEXT',
    //             icon: 'bx-bitcoin',
    //             subItems: [
    //                 {
    //                     id: 45,
    //                     label: 'MENUITEMS.CRYPTO.LIST.WALLET',
    //                     link: '/crypto/wallet',
    //                     parentId: 44
    //                 },
    //                 {
    //                     id: 46,
    //                     label: 'MENUITEMS.CRYPTO.LIST.BUY/SELL',
    //                     link: '/crypto/buy-sell',
    //                     parentId: 44
    //                 },
    //                 {
    //                     id: 47,
    //                     label: 'MENUITEMS.CRYPTO.LIST.EXCHANGE',
    //                     link: '/crypto/exchange',
    //                     parentId: 44
    //                 },
    //                 {
    //                     id: 48,
    //                     label: 'MENUITEMS.CRYPTO.LIST.LENDING',
    //                     link: '/crypto/lending',
    //                     parentId: 44
    //                 },
    //                 {
    //                     id: 49,
    //                     label: 'MENUITEMS.CRYPTO.LIST.ORDERS',
    //                     link: '/crypto/orders',
    //                     parentId: 44
    //                 },
    //                 {
    //                     id: 50,
    //                     label: 'MENUITEMS.CRYPTO.LIST.KYCAPPLICATION',
    //                     link: '/crypto/kyc-application',
    //                     parentId: 44
    //                 },
    //                 {
    //                     id: 51,
    //                     label: 'MENUITEMS.CRYPTO.LIST.ICOLANDING',
    //                     link: '/crypto-ico-landing',
    //                     parentId: 44
    //                 }
    //             ]
    //         },
    //         {
    //             id: 52,
    //             label: 'MENUITEMS.PROJECTS.TEXT',
    //             subItems: [
    //                 {
    //                     id: 53,
    //                     label: 'MENUITEMS.PROJECTS.LIST.GRID',
    //                     link: '/projects/grid',
    //                     parentId: 52
    //                 },
    //                 {
    //                     id: 54,
    //                     label: 'MENUITEMS.PROJECTS.LIST.PROJECTLIST',
    //                     link: '/projects/list',
    //                     parentId: 52
    //                 },
    //                 {
    //                     id: 55,
    //                     label: 'MENUITEMS.PROJECTS.LIST.OVERVIEW',
    //                     link: '/projects/overview',
    //                     parentId: 52
    //                 },
    //                 {
    //                     id: 56,
    //                     label: 'MENUITEMS.PROJECTS.LIST.CREATE',
    //                     link: '/projects/create',
    //                     parentId: 52
    //                 }
    //             ]
    //         },
    //         {
    //             id: 57,
    //             label: 'MENUITEMS.TASKS.TEXT',
    //             subItems: [
    //                 {
    //                     id: 58,
    //                     label: 'MENUITEMS.TASKS.LIST.TASKLIST',
    //                     link: '/tasks/list',
    //                     parentId: 57
    //                 },
    //                 {
    //                     id: 59,
    //                     label: 'MENUITEMS.TASKS.LIST.KANBAN',
    //                     link: '/tasks/kanban',
    //                     parentId: 57
    //                 },
    //                 {
    //                     id: 60,
    //                     label: 'MENUITEMS.TASKS.LIST.CREATETASK',
    //                     link: '/tasks/create',
    //                     parentId: 57
    //                 }
    //             ]
    //         },
    //         {
    //             id: 61,
    //             label: 'MENUITEMS.CONTACTS.TEXT',
    //             icon: 'bxs-user-detail',
    //             subItems: [
    //                 {
    //                     id: 62,
    //                     label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
    //                     link: '/contacts/grid',
    //                     parentId: 61
    //                 },
    //                 {
    //                     id: 63,
    //                     label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
    //                     link: '/contacts/list',
    //                     parentId: 61
    //                 },
    //                 {
    //                     id: 64,
    //                     label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
    //                     link: '/contacts/profile',
    //                     parentId: 61
    //                 }
    //             ]
    //         },
    //         {
    //             id: 65,
    //             label: 'MENUITEMS.BLOG.TEXT',
    //             subItems: [
    //                 {
    //                     id: 66,
    //                     label: 'MENUITEMS.BLOG.LIST.BLOGLIST',
    //                     link: '/blog/list',
    //                     parentId: 65
    //                 },
    //                 {
    //                     id: 67,
    //                     label: 'MENUITEMS.BLOG.LIST.BLOGGRID',
    //                     link: '/blog/grid',
    //                     parentId: 65
    //                 },
    //                 {
    //                     id: 68,
    //                     label: 'MENUITEMS.BLOG.LIST.DETAIL',
    //                     link: '/blog/detail',
    //                     parentId: 65
    //                 },
    //             ]
    //         },
    //     ]
    // },
    // {
    //     id: 69,
    //     icon: 'bx-collection',
    //     label: 'MENUITEMS.COMPONENTS.TEXT',
    //     subItems: [
    //         {
    //             id: 70,
    //             label: 'MENUITEMS.FORMS.TEXT',
    //             subItems: [
    //                 {
    //                     id: 71,
    //                     label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
    //                     link: '/form/elements',
    //                     parentId: 70
    //                 },
    //                 {
    //                     id: 72,
    //                     label: 'MENUITEMS.FORMS.LIST.VALIDATION',
    //                     link: '/form/validation',
    //                     parentId: 70
    //                 },
    //                 {
    //                     id: 73,
    //                     label: 'MENUITEMS.FORMS.LIST.ADVANCED',
    //                     link: '/form/advanced',
    //                     parentId: 70
    //                 },
    //                 {
    //                     id: 74,
    //                     label: 'MENUITEMS.FORMS.LIST.EDITOR',
    //                     link: '/form/editor',
    //                     parentId: 70
    //                 },
    //                 {
    //                     id: 75,
    //                     label: 'MENUITEMS.FORMS.LIST.FILEUPLOAD',
    //                     link: '/form/uploads',
    //                     parentId: 70
    //                 },
    //                 {
    //                     id: 76,
    //                     label: 'MENUITEMS.FORMS.LIST.REPEATER',
    //                     link: '/form/repeater',
    //                     parentId: 70
    //                 },
    //                 {
    //                     id: 77,
    //                     label: 'MENUITEMS.FORMS.LIST.WIZARD',
    //                     link: '/form/wizard',
    //                     parentId: 70
    //                 },
    //                 {
    //                     id: 78,
    //                     label: 'MENUITEMS.FORMS.LIST.MASK',
    //                     link: '/form/mask',
    //                     parentId: 70
    //                 }
    //             ]
    //         },
    //         {
    //             id: 79,
    //             label: 'MENUITEMS.TABLES.TEXT',
    //             subItems: [
    //                 {
    //                     id: 80,
    //                     label: 'MENUITEMS.TABLES.LIST.BASIC',
    //                     link: '/tables/basic',
    //                     parentId: 79
    //                 },
    //                 {
    //                     id: 81,
    //                     label: 'MENUITEMS.TABLES.LIST.ADVANCED',
    //                     link: '/tables/advanced',
    //                     parentId: 79
    //                 }
    //             ]
    //         },
    //         {
    //             id: 82,
    //             label: 'MENUITEMS.CHARTS.TEXT',
    //             subItems: [
    //                 {
    //                     id: 83,
    //                     label: 'MENUITEMS.CHARTS.LIST.APEX',
    //                     link: '/charts/apex',
    //                     parentId: 82
    //                 },
    //                 {
    //                     id: 84,
    //                     label: 'MENUITEMS.CHARTS.LIST.CHARTJS',
    //                     link: '/charts/chartjs',
    //                     parentId: 82
    //                 },
    //                 {
    //                     id: 85,
    //                     label: 'MENUITEMS.CHARTS.LIST.CHARTIST',
    //                     link: '/charts/chartist',
    //                     parentId: 82
    //                 },
    //                 {
    //                     id: 86,
    //                     label: 'MENUITEMS.CHARTS.LIST.ECHART',
    //                     link: '/charts/echart',
    //                     parentId: 82
    //                 }
    //             ]
    //         },
    //         {
    //             id: 87,
    //             label: 'MENUITEMS.ICONS.TEXT',
    //             subItems: [
    //                 {
    //                     id: 88,
    //                     label: 'MENUITEMS.ICONS.LIST.BOXICONS',
    //                     link: '/icons/boxicons',
    //                     parentId: 87
    //                 },
    //                 {
    //                     id: 89,
    //                     label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
    //                     link: '/icons/materialdesign',
    //                     parentId: 87
    //                 },
    //                 {
    //                     id: 90,
    //                     label: 'MENUITEMS.ICONS.LIST.DRIPICONS',
    //                     link: '/icons/dripicons',
    //                     parentId: 87
    //                 },
    //                 {
    //                     id: 91,
    //                     label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
    //                     link: '/icons/fontawesome',
    //                     parentId: 87
    //                 },
    //             ]
    //         },
    //         {
    //             id: 92,
    //             label: 'MENUITEMS.MAPS.TEXT',
    //             subItems: [
    //                 {
    //                     id: 93,
    //                     label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
    //                     link: '/maps/google',
    //                     parentId: 92
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     id: 94,
    //     label: 'HEADER.EXTRA_PAGES.TITLE',
    //     icon: 'bx-file',
    //     subItems: [
    //         {
    //             id: 95,
    //             label: 'MENUITEMS.INVOICES.TEXT',
    //             subItems: [
    //                 {
    //                     id: 96,
    //                     label: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
    //                     link: '/invoices/list',
    //                     parentId: 95
    //                 },
    //                 {
    //                     id: 97,
    //                     label: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
    //                     link: '/invoices/detail',
    //                     parentId: 95
    //                 },
    //             ]
    //         },
    //         {
    //             id: 98,
    //             label: 'MENUITEMS.AUTHENTICATION.TEXT',
    //             subItems: [
    //                 {
    //                     id: 99,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
    //                     link: '/pages/login-1',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 100,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN2',
    //                     link: '/pages/login-2',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 101,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
    //                     link: '/pages/register-1',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 102,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER2',
    //                     link: '/pages/register-2',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 103,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
    //                     link: '/pages/recoverpwd-1',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 104,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD2',
    //                     link: '/pages/recoverpwd-2',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 105,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
    //                     link: '/pages/lock-screen-1',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 106,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN2',
    //                     link: '/pages/lock-screen-2',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 107,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL',
    //                     link: '/pages/confirm-mail',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 108,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL2',
    //                     link: '/pages/confirm-mail-2',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 109,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
    //                     link: '/pages/email-verification',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 110,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION2',
    //                     link: '/pages/email-verification-2',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 111,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
    //                     link: '/pages/two-step-verification',
    //                     parentId: 98
    //                 },
    //                 {
    //                     id: 112,
    //                     label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION2',
    //                     link: '/pages/two-step-verification-2',
    //                     parentId: 98
    //                 }
    //             ]
    //         },
    //         {
    //             id: 113,
    //             label: 'MENUITEMS.UTILITY.TEXT',
    //             icon: 'bx-file',
    //             subItems: [
    //                 {
    //                     id: 114,
    //                     label: 'MENUITEMS.UTILITY.LIST.STARTER',
    //                     link: '/pages/starter',
    //                     parentId: 113
    //                 },
    //                 {
    //                     id: 115,
    //                     label: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
    //                     link: '/pages/maintenance',
    //                     parentId: 113
    //                 },
    //                 {
    //                     id: 116,
    //                     label: 'Coming Soon',
    //                     link: '/pages/coming-soon',
    //                     parentId: 113
    //                 },
    //                 {
    //                     id: 116,
    //                     label: 'MENUITEMS.UTILITY.LIST.TIMELINE',
    //                     link: '/pages/timeline',
    //                     parentId: 113
    //                 },
    //                 {
    //                     id: 117,
    //                     label: 'MENUITEMS.UTILITY.LIST.FAQS',
    //                     link: '/pages/faqs',
    //                     parentId: 113
    //                 },
    //                 {
    //                     id: 118,
    //                     label: 'MENUITEMS.UTILITY.LIST.PRICING',
    //                     link: '/pages/pricing',
    //                     parentId: 113
    //                 },
    //                 {
    //                     id: 119,
    //                     label: 'MENUITEMS.UTILITY.LIST.ERROR404',
    //                     link: '/pages/404',
    //                     parentId: 113
    //                 },
    //                 {
    //                     id: 120,
    //                     label: 'MENUITEMS.UTILITY.LIST.ERROR500',
    //                     link: '/pages/500',
    //                     parentId: 113
    //                 },
    //             ]
    //         }
    //     ]
    // }
];

