import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
/**
 * Login-2 component
 */
export class Login2Component implements OnInit {
  loginForm: FormGroup;
  tfaFlag: boolean = false
  userObject = {
  }
  errorMessage: string = null
  submitted = false;
  error = '';
  returnUrl: string;
  public showPassword: boolean = false;
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService) { }
 

  ngOnInit() {
    // const currentUser = this.authFackservice.currentUserValue;
    // if (currentUser) {
    //     // logged in so return true
    //     return true;
    // }

    this.loginForm = this.formBuilder.group({
      uname: ['', [Validators.required, Validators.email]],
      upass: ['Daddy@143', [Validators.required]],
      authcode: ['', [Validators.required]]
    }); 

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    dots: true,
    responsive: {
      680: {
        items: 1
      },
    }
  }

  get f() { return this.loginForm.controls; }

  public onPasswordToggle(): void {
    this.showPassword = !this.showPassword;
    console.log('changed')
  } 

  onSubmit() {

    if (this.loginForm.value.authcode != '') {
      this.userObject = {
        uname: this.loginForm.value.uname,
          upass: this.loginForm.value.upass, 
          authcode: this.loginForm.value.authcode  
      }
    }else{
      this.userObject = {
        uname: this.loginForm.value.uname,
          upass: this.loginForm.value.upass, 
      }
    }
console.log(this.userObject);

    this.submitted = true;

this.authFackservice.loginAuth(this.userObject).subscribe((datas) => {
  console.log(datas);
  
  this.errorMessage = null;
  if (datas.body['status'] === 200) {
    var good = datas.body
    localStorage.setItem('user_data', JSON.stringify(good['data']) );
    localStorage.setItem('currentUser',JSON.stringify(good['data'])); 
    localStorage.setItem('usr_id',JSON.stringify(good['data'].u_id));
    localStorage.setItem('country_id',JSON.stringify(good['data'].country_id)); 
    localStorage.setItem('role',  good['data'].variation_indicator);
    console.log(good['data']);
    
    this.authFackservice.updateAuthStatus(true); 
    this.router.navigate(['/account/setup']);  
    console.log('route change');
    

  }
  if (datas.body['status'] === 202) {
    var good = datas.body;
    localStorage.setItem('user_data', JSON.stringify(good['data']) );
    localStorage.setItem('currentUser',JSON.stringify(good['data']));
    localStorage.setItem('usr_id',JSON.stringify(good['data'].u_id));
    localStorage.setItem('country_id',JSON.stringify(good['data'].country_id)); 
    localStorage.setItem('role',  good['data'].variation_indicator);
    console.log(good['data'].variation_indicator);

    this.authFackservice.updateAuthStatus(true); 
    // this.authFackservice.updateAuthStatus(true); 

    if (good['data'].variation_indicator == 0) {
      this.router.navigate([  '/users' ]); 
    }else  if (good['data'].variation_indicator == 1) {
      this.router.navigate([  '/userdetails']); 
    }else  if (good['data'].variation_indicator == 2) {
      this.router.navigate([  '/franchiseusers']); 
    }
    
     
    console.log('route change sucess');
    
  }
  if (datas.body['status'] === 206) {
    var good = datas.body
    localStorage.setItem('user_data', JSON.stringify(good['data']) );
    localStorage.setItem('currentUser',JSON.stringify(good['data']));
    localStorage.setItem('usr_id',JSON.stringify(good['data'].u_id));
    localStorage.setItem('country_id',JSON.stringify(good['data'].country_id)); 
    console.log(good['message']); 
    this.tfaFlag = true;
    this.errorMessages();
  }
  if (datas.body['status'] === 403) {
    this.errorMessage = datas.body['message'];
  }
  if (datas.body['status'] === 404) { 
    this.errorMessage = datas.body['message'];
  }
})
    // stop here if form is invalid
    // if (this.loginForm.invalid) {
    //   return;
    // } else {
    //   if (environment.defaultauth === 'firebase') {
    //     this.authenticationService.login(this.f.email.value, this.f.password.value).then((res: any) => {
    //       this.router.navigate(['/viewprofile']);
    //     })
    //       .catch(error => {
    //         this.error = error ? error : '';
    //       });
    //   } else {

 
    //     // this.userObject = {
    //     //   uname: this.loginForm.value.email,
    //     //   upass: this.loginForm.value.password, 
    //     //   authcode: this.loginForm.value.authcode  
    //     // }
    //     console.log('submitted1');
    

    //     // this.authFackservice.login(this.f.email.value, this.f.password.value)
    //     //   .pipe(first())
    //     //   .subscribe(
    //     //     data => {
    //     //       this.router.navigate(['/users']);
    //     //     },
    //     //     error => {
    //     //       this.error = error ? error : '';
    //     //     });
    //   }
    // } 
  }


  errorMessages() {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Auth Code',
      showConfirmButton: false,
      timer: 1500
    });
  }

}
