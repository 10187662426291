<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                 © 2022
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    All Copyrights Reserve To HSCODES.WORLD
                </div>
            </div>
        </div>
    </div>
</footer>