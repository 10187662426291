import { Component , OnInit} from '@angular/core'; 
import { OneSignalService } from 'onesignal-ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
constructor(private oneSignal: OneSignalService){ 
//   this.oneSignal.init({
//     appId: "YOUR-ONESIGNAL-APP-ID",
// });
}
  ngOnInit() {
    
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
